import { Fade } from '@mui/material'

export const LandingPage = () => {

    return (
        <Fade in={true} timeout={1000}>
            <div style={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: "center",
                alignItems:'center',
                backgroundColor: '#000'}}>
                <img src={process.env.PUBLIC_URL + '/images/rubix.webp'} alt="Rubix Eye" style={{maxWidth:'100%'}} />
            </div>
        </Fade>
    )
}