import './App.css';
import { LandingPage } from './pages/Landing';

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
